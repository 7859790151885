import styled from 'styled-components';
import { accent, primaryFont, secondaryFont } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';
import { Link as _Link } from '@reach/router';

export const Container = styled.footer`
  display: flex;
  background-color: #050709;
  color: white;
  padding-top: 80px;
  padding-bottom: 65px;
`;

export const Content = styled.div`
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-left: auto;
  margin-right: auto;
`;

export const Column = styled.div`
  flex: 1;
  margin-bottom: 50px;
`;

export const ColumnHeader = styled.h3`
  color: white;
  font-size: ${({ large }) => (large ? '2rem' : '1.6rem')};
  ${({ uppercase }) => (uppercase ? 'text-transform: uppercase;' : '')}
  line-height: 3rem;
  font-weight: 400;
  font-family: ${primaryFont};
  margin: -7px 0 24px;
`;

export const ItemHeader = styled.div`
  color: white;
  font-size: 1.3rem;
  line-height: 2.4rem;
  font-weight: 400;
  text-transform: uppercase;
  font-family: ${primaryFont};
`;

export const ItemValue = styled.div`
  color: #c2c2c2;
  line-height: 2.4rem;
  font-size: 1.6rem;
`;

export const ItemGroup = styled.div`
  margin-bottom: 1.5rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;

  ${MEDIA.MIN_TABLET`
    flex-direction: row;
  `}
`;

export const LinkList = styled.ul``;

export const LinkListItem = styled.li`
  margin-bottom: 2.2rem;
  line-height: 1.6rem;
`;
export const Link = styled(_Link)`
  position: relative;
  color: #c2c2c2;
  font-size: 1.6rem;
  font-weight: 400;
  transition: all 500ms ease;
  text-decoration: none;

  &:hover {
    padding-left: 20px;
    color: ${accent};

    &::before,
    &::after {
      opacity: 1;
    }
  }

  &::before {
    position: absolute;
    top: 3px;
    left: 2px;
    width: 1px;
    height: 13px;
    background: ${accent};
    content: '';
    transform: rotate(15deg);
    opacity: 0;
    transition: all 0.3s ease-in-out 0.1s;
  }

  &::after {
    position: absolute;
    top: 3px;
    left: 7px;
    width: 1px;
    height: 13px;
    background: ${accent};
    content: '';
    transform: rotate(15deg);
    opacity: 0;
    transition: all 0.4s ease-in-out 0.1s;
  }
`;
