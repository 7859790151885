import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import { Container, Bar } from './header.css';
import ContactBar from './contactBar/contactBar';
import Navbar from 'components/header/nav/navbar';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const Header = ({ title, companyInfo }) => (
  <AnimatedContainer>
    <Container>
      <Bar dark>
        <ContactBar companyInfo={companyInfo} />
      </Bar>
      <Bar>
        <Navbar title={title} />
      </Bar>
    </Container>
  </AnimatedContainer>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  companyInfo: PropTypes.shape({
    phone: PropTypes.string,
    email: PropTypes.string,
  }),
};

export default Header;
