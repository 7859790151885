// This does not support nested pages (level 2 and up)
// If you're working with deeply nested pages, remove this or rework it.

export default ({
  location,
  canonical,
  siteUrl,
  pageTitle,
  siteTitle,
  pageTitleFull,
  companyInfo,
  image,
}) => {
  const isSubPage = pageTitle && location.pathname !== '/';
  let schema = [
    {
      '@context': 'http://schema.org',
      '@type': 'LocalBusiness',
      sameAs: canonical,
      url: canonical,
      name: companyInfo.companyName,
      email: companyInfo.email,
      telephone: companyInfo.phone,
      alternateName: pageTitleFull,
      address: {
        '@type': 'PostalAddress',
        addressCountry: 'PL',
        postalCode: companyInfo.zipCode,
        streetAddress: companyInfo.address,
      },
      image: siteUrl + image,
      taxId: companyInfo.nip,
    },
  ];

  if (isSubPage) {
    schema.push({
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': siteUrl,
            name: siteTitle,
          },
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            '@id': canonical,
            name: pageTitle,
          },
        },
      ],
    });
  }

  return schema;
};
