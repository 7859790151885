import styled from 'styled-components';
import { accent, primaryFont, secondaryFont } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 100%;

  ${MEDIA.MIN_TABLET`
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  `}
`;

export const Info = styled.span`
  color: #c2c2c2;
  display: inline-block;
  margin: 0.5rem 0;
  font-size: 1.4rem;
  font-family: ${secondaryFont};

  ${MEDIA.MIN_TABLET`
    margin-left: 1rem;
  `}
`;

export const Title = styled.span`
  color: ${accent};
  font-weight: 600;
  font-family: ${primaryFont};
  display: inline-block;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  ${MEDIA.MIN_TABLET`
    margin-bottom: 0;
  `}
`;
