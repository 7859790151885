import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './navbar.css';
import { Link } from '@reach/router';
import Title from 'components/title';
import Nav from './nav';

const Navbar = ({ title }) => {
  return (
    <Container>
      <Link to="/">
        <Title as="h1">{title}</Title>
      </Link>

      <Nav />
    </Container>
  );
};

Navbar.propTypes = {
  title: PropTypes.string,
};

export default Navbar;
