import React from 'react';
import {
  Container,
  Content,
  Row,
  Column,
  ColumnHeader,
  ItemHeader,
  ItemValue,
  ItemGroup,
  LinkList,
  LinkListItem,
  Link,
} from './footer.css';

const Footer = ({ companyInfo: { phone, email, nip }, localServices }) => {
  return (
    <Container>
      <Content>
        <Row>
          <Column>
            <ColumnHeader large>Zapraszamy do współpracy</ColumnHeader>
            <ItemGroup>
              <ItemHeader>Telefon</ItemHeader>
              <ItemValue>{phone}</ItemValue>
            </ItemGroup>
            <ItemGroup>
              <ItemHeader>E-mail</ItemHeader>
              <ItemValue>{email}</ItemValue>
            </ItemGroup>
            <ItemGroup>
              <ItemHeader>NIP</ItemHeader>
              <ItemValue>{nip}</ItemValue>
            </ItemGroup>
            <ItemGroup>
              <ItemHeader>Lokalizacja</ItemHeader>
              <ItemValue>Częstochowa, Śląskie, Polska</ItemValue>
            </ItemGroup>
          </Column>

          <Column>
            <ColumnHeader uppercase>O nas</ColumnHeader>
            <LinkList>
              <LinkListItem>
                <Link to={'/oferta'}>Oferta</Link>
              </LinkListItem>
              <LinkListItem>
                <Link to={'/kontakt'}>Kontakt</Link>
              </LinkListItem>
              <LinkListItem>
                <Link to={'/polityka-prywatnosci'}>Polityka prywatności</Link>
              </LinkListItem>
            </LinkList>
          </Column>

          <Column>
            <ColumnHeader uppercase>Oferta lokalna</ColumnHeader>
            <LinkList>
              {localServices.map(({ title, url }, index) => (
                <LinkListItem key={index}>
                  <Link to={url}>{title}</Link>
                </LinkListItem>
              ))}
            </LinkList>
          </Column>
        </Row>
      </Content>
    </Container>
  );
};

export default Footer;
