import React from 'react';
import PropTypes from 'prop-types';
import { Container, Info, Title } from './contactBar.css';

const ContactBar = ({ companyInfo: { phone, email } }) => {
  return (
    <Container>
      <Title>Skontaktuj się z nami: </Title>
      <Info>tel: {phone}</Info>
      <Info>email: {email}</Info>
    </Container>
  );
};

ContactBar.propTypes = {
  companyInfo: PropTypes.shape({
    phone: PropTypes.string,
    email: PropTypes.string,
  }),
};

export default ContactBar;
