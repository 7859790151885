import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  flex-direction: column;
  a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
`;

export const Bar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2rem 4rem;
  align-items: center;
  ${props => {
    if (props.dark) {
      return `
        background-color: #1f1f1f;
        color: white;
      `;
    }

    return '';
  }}
`;
