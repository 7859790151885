import React from 'react';
import { Container, List, Link } from './nav.css';

const Nav = () => (
  <Container>
    <List>
      <li>
        <Link to="/oferta">Oferta</Link>
      </li>
      <li>
        <Link to="/kontakt">Kontakt</Link>
      </li>
    </List>
  </Container>
);

export default Nav;
