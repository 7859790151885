import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Head from 'components/head';
import Header from 'components/header';
import GlobalStyle from 'global.css.js';
import Footer from 'components/footer';

const Layout = ({ data, children }) => {
  const {
    siteMetadata: { siteTitle, companyInfo },
  } = data.site;
  const { local } = data.ofertaJson;
  return (
    <div>
      <GlobalStyle />
      <Head />
      <Header title={siteTitle} companyInfo={companyInfo} />
      {children}
      <Footer companyInfo={companyInfo} localServices={local} />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
            companyInfo {
              phone
              email
              nip
            }
          }
        }
        ofertaJson {
          local {
            title
            url
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
