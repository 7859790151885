import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { primaryFont, black } from 'constants/theme';

// eslint-disable-next-line
export const Text = styled.span`
  display: block;
  ${({ isBlack }) => (isBlack ? `color: ${black};` : '')}
  font-family: ${primaryFont};
  font-weight: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '600';
      default:
        return '400';
    }
  }};
  font-size: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '3.2rem';
      default:
        return '2rem';
    }
  }};
  line-height: 1.2;

  ${MEDIA.TABLET`
    font-size: ${({ size }) => () => {
      switch (size) {
        case 'large':
          return '2.6rem';
        default:
          return '2rem';
      }
    }};
  `};
`;
