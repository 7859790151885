import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${MEDIA.MIN_TABLET`
    flex-direction: row;
    justify-content: space-between;
  `}

  a {
    display: inline-block;
  }
`;
