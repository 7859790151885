import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { Link as _Link } from 'gatsby';

export const Container = styled.nav`
  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 1rem 0;

    li {
      text-transform: uppercase;
      font-size: 1.3rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      ${MEDIA.MIN_TABLET`
        magin: 0;
      `}

      & + li {
        margin-left: 0rem;
        ${MEDIA.MIN_TABLET`
          margin-left: 2rem;
        `}
      }
    }

    ${MEDIA.MIN_TABLET`
      flex-direction: row;
    `}
  }
`;

export const List = styled.ul`
  text-align: center;
`;

export const Link = styled(_Link)`
  font-size: 1.4rem;
`;
